import NotFoundComponent from "./views/NotFoundComponent.vue"

export let testsRoute = { 
  path: "/tests",
  component: () => import("./views/tests/TestRouter.vue"),
  children: [
    {
      path: "test-webrtc",
      component: () => import("./views/tests/TestWebRTC.vue"),
    },
    {
      path: "event-flow",
      component: () => import("./views/tests/TestEventFlow.vue")
    },
    {
      path: "test-s",
      component: () => import("./views/tests/TestS.vue")
    },
    {
      path: "test-s-iospoc",
      component: () => import("./views/tests/TestSiOSPoC.vue")
    },
    {
      path: "test-s-iospoc2",
      component: () => import("./views/tests/TestSiOSPoC2.vue")
    },
    {
      path: "test-s2",
      component: () => import("./views/tests/TestS2.vue")
    },
    {
      path: "test-s3",
      component: () => import("./views/tests/TestS3.vue")
    },
    {
      path: "test-room",
      component: () => import("./views/tests/TestRoom.vue")
    },
    {
      path: "test-roomlogs",
      component: () => import("./views/tests/TestRoomLogs.vue")
    },
    {
      path: "test-spectrum",
      component: () => import("./views/tests/TestSpectrum.vue")
    },
    {
      path: "test-spectrum2",
      component: () => import("./views/tests/TestSpectrum2.vue")
    },
    {
      path: "multi-guests/:eventId",
      component: () => import("./views/tests/TestMultiGuests.vue")
    },
    {
      path: "functions",
      component: () => import("./views/tests/TestFunctions.vue")
    },
    {
      path: "form-ios",
      component: () => import("./views/tests/TestFormiOS.vue")
    },
    {
      path: "ios-loop",
      component: () => import("./views/tests/TestiOSLoop.vue")
    },
    {
      path: "webrtc-ios",
      component: () => import("./views/tests/TestWebRTCiOS.vue")
    },
    {
      path: "landing-page",
      component: () => import("./views/tests/TestLandingPage.vue")
    },
    {
      path: "functions-performance",
      component: () => import("./views/tests/TestFunctionsPerformance.vue")
    },
    {
      path: "utils",
      component: () => import("./views/tests/TestUtils.vue")
    },
    {
      path: "error",
      component: () => import("./views/tests/TestError.vue")
    },
    {
      path: "test-studio",
      component: () => import("./views/tests/TestStudio.vue")
    },
    {
      path: "test-composer-assets-override",
      component: () => import("./views/tests/TestComposerAssetsOverride.vue")
    },
    {
      path: "test-gum",
      component: () => import("./views/tests/TestGUM.vue")
    },
    {
      path: "test-streaming",
      component: () => import("./views/tests/TestStreaming.vue")
    },
    {
      path: "test-hls",
      component: () => import("./views/tests/TestHLS.vue")
    },
    {
      path: "test-mime-types",
      component: () => import("./views/tests/TestMimeTypes.vue")
    },
    {
      path: "test-canvg",
      component: () => import("./views/tests/TestCanVG.vue")
    },
    {
      path: "test-composition",
      component: () => import("./views/tests/TestComposition.vue")
    },
    {
      path: "test-composer",
      component: () => import("./views/tests/TestComposer.vue")
    },
    {
      path: "test-audio",
      component: () => import("./views/tests/TestAudio.vue")
    },
    {
      path: "test-ffmpeg-hls",
      component: () => import("./views/tests/TestFFMPEGHLS.vue")
    },
    {
      path: "test-msb",
      component: () => import("./views/tests/TestMSB.vue")
    },
    {
      path: "test-ui",
      component: () => import("./views/tests/TestUI.vue")
    },
    {
      path: "test-preview-gum",
      component: () => import("./views/tests/TestPreviewGUM.vue")
    },
    {
      path: "test-broadcast",
      component: () => import("./views/tests/TestBroadcast.vue")
    },
    {
      path: "test-sfu",
      component: () => import("./views/tests/TestSFU.vue")
    },
    {
      path: "test-template-editor",
      component: () => import("./views/tests/TestTemplateEditor.vue")
    },
    {
      path: "test-gum-component",
      component: () => import("./views/tests/TestGUMComponent.vue")
    },
    {
      path: '*', 
      component: NotFoundComponent 
    }
  ]
}