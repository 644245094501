//console.log("firebase-client")

import firebase from "firebase/app";
import "@firebase/firestore";
import "@firebase/storage";
import "@firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyA4JLYPcaNW3GPs31GXx1t9bxCjmhwixT8",
  authDomain: "livem-demo.firebaseapp.com",
  databaseURL: "https://livem-demo.firebaseio.com",
  projectId: "livem-demo",
  storageBucket: "livem-demo.appspot.com",
  messagingSenderId: "898845609350",
  appId: "1:898845609350:web:7fb34acaf212f76631b007",
  measurementId: "G-TR05S361P5"
};

export let app = firebase.initializeApp(firebaseConfig);

let askUseEmulators = false;

export let useEmulators = askUseEmulators && (window.location.hostname === "localhost" || window.location.hostname.startsWith("192.168"));

export { firebase };