//let productionStreamingServerIp = "wss://streamozoa.ngrok.io";
let productionStreamingServerIp = "wss://streaming.happyhour.live";

let baseConfig = {
  showBetaWarning: false,
  // Servers
  //streamingServerIp: "ws://localhost:3000",
  streamingServerIp: productionStreamingServerIp,
  useStreamingServerForServing: false,
}

// Production override
if (process.env.NODE_ENV == 'production') {
  baseConfig.streamingServerIp = productionStreamingServerIp;
}

// Environment determination
if (global.window) {
  if (global.window.location.hostname.startsWith("app."))
    baseConfig.environment = null;
  else if (global.window.location.hostname.startsWith("app-staging."))
    baseConfig.environment = "staging";
  else
    baseConfig.environment = "dev";
}

let happyHour = {
  // Branding
  productName: "Happy Hour",
  productNameIcon: "HappyHour",
  productNameLC: "happyhour",
  tagLine: "Live streaming with guests",
  companyName: "Massive Finger Inc. d/b/a Happy Hour",
  eventName: "Live Show",
  contactEmail: "hello@happyhour.live",
  productURL: "https://www.happyhour.live",
  appURL: "https://app.happyhour.live",

  // Side Menu Items
  menuItems: ['events', 'account'],

  // Flags
  useRtcComponent: "roomRTC", // roomRTC | peerRTC
  useiOSNativeWebRTC: false,
  limitedAvailability: true,
  redirectToSignupFromHomepage: false,
  skipAccount: true,
  skipToU: true, // Skiping ToU because they are provided on the sign in screen.
  disableEventCalendar: false,
  eventsShowPublic: false,
  eventsAllowCreation: true, // Should be changed on an invidual basis.  
  sendEmailNotifications: false,

  // Assets
  assets(c) {
    c.productLogoTitle = require("@/assets/happyhour/title.png");
    c.productLogoSmall = require("@/assets/happyhour/logo_small.png");
    return c;
  }
}

let officeHours = {
  // Branding
  productName: "Office Hours",
  productNameIcon: "OfficeHours",
  productNameLC: "officehours",
  tagLine: "Quick meetings",
  companyName: "Massive Finger Inc. d/b/a Office Hours",
  eventName: "Meeting",
  contactEmail: "hello@officehours.one",
  productURL: "https://www.officehours.one",
  appURL: "https://app.officehours.one",

  // Side Menu Items
  menuItems: [],

  // Flags
  useRtcComponent: "roomRTC", // roomRTC | peerRTC
  disableEventCalendar: true,
  limitedAvailability: true,
  redirectToSignupFromHomepage: false,
  skipAccount: true,
  skipToU: true, // Skiping ToU because they are provided on the sign in screen.
  sendEmailNotifications: true,

  // Assets
  assets(c) {
    c.productLogoTitle = require("@/assets/officehours/title_oh.png");
    c.productLogoSmall = require("@/assets/officehours/logo_small_oh.png");
    return c;
  }  
}

let configName = process.env.VUE_APP_VARIANT || "hh";

let config = Object.assign(baseConfig, configName == "oh" ? officeHours : happyHour);
config.appVariant = configName;

if (process.env.VUE_APP_PLATFORM == "ios") {
  config = Object.assign(config, {
    limitedAvailability: false,
    redirectToSignupFromHomepage: true,
    signInOptionsNoGoogle: true,
    skipAccount: false,
  });
}

let appConfig = typeof window == "undefined" ? config : config.assets(config);

let appConfigPlugin = {
  install(Vue) {
    Vue.prototype.$app = appConfig;
  }
}

module.exports = {
  productionStreamingServerIp,
  config,
  appConfig,
  appConfigPlugin
}