//console.log("db-client");

import { firebase, useEmulators } from "./firebase";

export let db = firebase.firestore();

if (useEmulators && (window.location.hostname === "localhost" || window.location.hostname.startsWith("192.168"))) {
  db.settings({
    host: "localhost:8880",
    ssl: false
  });
}

export let storage = firebase.storage();

export let database = firebase.database();

export let serverTimestamp = () => firebase.firestore.FieldValue.serverTimestamp();

export let fieldDelete = () => firebase.firestore.FieldValue.delete();

export let timestampFromDate = (date) => firebase.firestore.Timestamp.fromDate(date);
