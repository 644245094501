import { firebase, useEmulators } from "./firebase";
import "firebase/functions";

export let functions = firebase.functions();

let useFunctionsEmulators = useEmulators;

if (useFunctionsEmulators && (window.location.hostname == "localhost" || window.location.hostname.startsWith("192.168.")))
  functions.useFunctionsEmulator(`http://${window.location.hostname}:5001`);

export let helloWorld = firebase.functions().httpsCallable("auth-helloWorld");
export let upgradeAuth = firebase.functions().httpsCallable("auth-upgradeAuth");
export let setEventAutomation = firebase.functions().httpsCallable("event-setEventAutomation");
export let emulatorScheduledEventUpdate = firebase.functions().httpsCallable("event-emulatorScheduledEventUpdate");
export let testsEmulatorScheduledEventUpdate = firebase.functions().httpsCallable("tests-emulatorScheduledEventUpdate");
export let testsFirestorePerformanceNormal = firebase.functions().httpsCallable("tests-firestorePerformanceNormal");
export let testsFirestorePerformanceSlow = firebase.functions().httpsCallable("tests-firestorePerformanceSlow");
export let getTimeMS = firebase.functions().httpsCallable("event-getTimeMS");
export let getChannelToken = firebase.functions().httpsCallable("agora-getChannelToken");